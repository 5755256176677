import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/club",
  },
  // 俱乐部大屏
  {
    path: "/club/:id",
    name: "Club",
    component: () => import("../views/Club"),
  },
];

const router = new VueRouter({
  mode: "history", // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

export default router;
