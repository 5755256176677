import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./style/main.scss";
import "lib-flexible";
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$moment = moment;
ElementUI.Dialog.props.lockScroll.default = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
