import request from "@/utils/request";
// 登录
const login = (params) => request.post("/boxing/web/admin/webLogin", params);
// 数据字典
const getDictCode = (params) =>
  request.post("/boxing/systemSet/queryListByDictCode", params);
// 获取大屏基础信息
const getClubInfo = (params) =>
  request.post("/boxing/web/club/clubBasicInfo", params);
// 获取用户训练数据
const getFreedomList = (params) =>
  request.post("/boxing/web/club/clubRangeUserTrainingDataShow", params);
export { login, getDictCode, getClubInfo, getFreedomList };
