import { login } from "@/api/index";
import { setToken, removeToken } from "@/utils/auth";

const user = {
  state: {
    token: "",
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
  },

  actions: {
    // 登录
    enterLogin({ commit }, userInfo) {
      // console.log("userInfo :>> ", userInfo, commit);
      const account = userInfo.account.trim();
      const password = userInfo.password;
      return new Promise((resolve, reject) => {
        login({ account, password })
          .then((res) => {
            // console.log("res.data :>> ", res);
            setToken(res.result);
            commit("SET_TOKEN", res.result);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // // 获取用户信息
    // GetInfo({ commit, state }) {
    //   return new Promise((resolve, reject) => {
    //     getInfo().then(res => {
    //       // 没有 data 数据，赋予个默认值
    //       if (!res) {
    //         res = {
    //           data: {
    //             roles: [],
    //             user: {
    //               id: '',
    //               avatar: '',
    //               userName: '',
    //               nickname: ''
    //             }
    //           }
    //         }
    //       }

    //       res = res.data; // 读取 data 数据
    //       const user = res.user
    //       const avatar = ( user.avatar === "" || user.avatar == null ) ? require("@/assets/images/profile.jpg") : user.avatar;
    //       if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
    //         commit('SET_ROLES', res.roles)
    //         commit('SET_PERMISSIONS', res.permissions)
    //       } else {
    //         commit('SET_ROLES', ['ROLE_DEFAULT'])
    //       }
    //       commit('SET_ID', user.id)
    //       commit('SET_NAME', user.userName)
    //       commit('SET_NICKNAME', user.nickname)
    //       commit('SET_AVATAR', avatar)
    //       resolve(res)
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },

    // 退出系统
    LogOut({ commit }) {
      return new Promise((resolve) => {
        // loginOut()
        //   .then(() => {
        commit("SET_TOKEN", null);

        removeToken();
        resolve();
        // })
        // .catch((error) => {
        //   reject(error);
        // });
      });
    },
  },
};

export default user;
